import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../assets/scss/newstyle.scss'
import blogimg1 from '../assets/img/Picture2.jpg'
import blogimg2 from '../assets/img/Launchevent.jpg'
import blogimg3 from '../assets/img/image111.jpg'
import blogimg5 from '../assets/img/Picture14.jpg'
import blogimg15 from '../assets/img/image45.png'
import blogimg16 from '../components/Navbars/manibot-final.png'


const BlogList2 = () => {




  return (
    <div id="layout" className="pure-g">
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts" >
		  <section >
                	<div class="container py-2">
		<div class="h1 text-center text-dark" id="pageHeaderTitle"></div>
		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg16} style={{height:'320px',paddingTop:'140px',paddingBottom:'133px',marginRight:'20px',marginLeft:'20px'}}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>TU WIEN develops ZS6D: Zero-Shot 6D Object Pose Estimation </a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						24/09/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">As robotic systems interact with increasingly complex environments, recognizing a wide variety
				of objects becomes crucial..</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/TU WIEN develops ZS6D: Zero-Shot 6D Object Pose Estimation'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg15} style={{height:'320px'}}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>MANiBOT research presented at ICRA 2024 </a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						28/05/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">MANiBOT was proudly present at this year’s IEEE International Conference on Robotics and Automation (ICRA 2024), taking place in Yokohama, Japan, from May 13-17.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/MANiBOT research presented at ICRA 2024'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg5} style={{height:'320px'}}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>MANiBOT Joins the First Automation and Robotics Expo in Athens </a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						07/05/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Project Coordinator CERTH showcased MANiBOT at the first Automation and Robotics Expo at the Metropolitan Expo Center in Athens, Greece in mid April.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/MANiBOT Joins the First Automation and Robotics Expo in Athens'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg3}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>MANiBOT Consortium gathers in Italy to discuss latest developments</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						27/04/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">MANiBOT partners came together from the 10-12th April in Pontedera, Italy, for their second in-person project plenary meeting, marking six months since the project officially commenced.</div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/MANiBOT Consortium gathers in Italy to discuss latest developments'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg2}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>MANiBOT debuts at online showcase event</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						01/03/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">On February 22nd MANiBOT had its public debut at an online event co-organised by Adra - AI-Data-Robotics-Association, </div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/MANiBOT debuts at online showcase event'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>

		<article class="postcard light blue">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img"  title="" src={blogimg1}/>
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a>MANiBOT project commences journey to revolutionise mobile robots</a></h1>
				<div class="postcard__subtitle small">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
						27/02/2024
					</time>
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">The MANiBOT project, a pioneering initiative aimed at empowering bimanual mobile robots for challenging manipulation tasks, was launched in Thessaloniki, Greece, on November 14-15, 2023. </div>
				<ul class="postcard__tagbox">
					
          <Link
                   to ='/MANiBOT project commences journey to revolutionise mobile robots'
                    className="buttonaki">
                    Read More
                  </Link>
				</ul>
			</div>
		</article>
    </div>
              
              </section>


         
          </div>


          
        </div>
      </div>
    </div>
  )
}

export default BlogList2